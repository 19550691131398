
import { Vue, Component } from "vue-property-decorator";
import { clone } from "ramda";

import { Auth } from "@/store/modules";
import { User } from "@/store/auth";

import EditPrivateProfile from "@/components/EditPrivateProfile.vue";
import EditPublicProfile from "@/components/EditPublicProfile.vue";

@Component({
  name: "Onboarding",
  components: {
    EditPrivateProfile,
    EditPublicProfile,
  },
})
export default class extends Vue {
  loading = false;
  error = "";
  step = 0;

  model: Partial<User> = {
    details: {},
  };

  get isEdit() {
    return (
      this.model && this.model.details && this.model.details.onboardingFinished
    );
  }

  async refresh() {
    this.loading = true;
    try {
      const user = await Auth.getSelfInformation();
      this.model = clone(user);
    } catch (err) {
      this.error = err.message;
    }
    this.loading = false;
  }

  async mounted() {
    await this.refresh();
    if (this.isEdit) {
      this.$router.push("/");
    }
  }

  onPrivateProfileSave(user: User) {
    if (user.role === "teacher") {
      this.step = 1;
    } else {
      this.$router.push("/");
    }
  }
}
